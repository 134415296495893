import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";

const customStyle = {
  ...atomDark,
  // Add more styles for other token types
};
const HtmlRenderer = ({ str }) => {
  if (!str) return "";

  const array = [];

  const blocks = str.split("code_snippet");

  for (let i = 0; i < blocks.length; i++) {
    try {
    const block = blocks[i].trim();

    let lang = "";
    let code = "";

    if (block.includes("lang=")) {
      const snippetMatch = block.match(/lang=(\w+)/);
      console.log( "snmatch", snippetMatch)
      lang = snippetMatch ? snippetMatch[1] : "";
      code = block.replace(snippetMatch[0], "").trim();
    } else {
      code = block;
    }
    array.push({ lang, code });
    } catch (error) {
      alert("your  Post Contain Error")
    }
  }
  return (
    <div>
      {array.map((block) => {
        return (
          <div>
            {block.lang ? (
              <SyntaxHighlighter  language={block.lang} style={customStyle}>
                {block.code}
              </SyntaxHighlighter>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: block.code }} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default HtmlRenderer;
