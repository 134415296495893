import React, { useEffect, useState } from "react";
import Education from "./Components/Education";
import Pofrfolio from "./Components/Pofrfolio";
import Banner from "./Components/Banner";
import axios from "axios";
import { baseURL } from "./constant";
import "./loading.css";
const App = () => {
  const [projects, setProjects] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [education, setEducation] = useState([]);

  // my state
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState({});
  const [loadingStates, setLoadingStates] = useState({
    bannerLoading: true,
    portfolioLoading: true,
    educationLoading: true,
  });
  const config = {
    headers: {
      Authorization: `Bearer 8df7fe79151479d523a1f34cea2f471a935a0ca1c5ba3b7f9a9a9dc79c18e8728cffd07a8a488ed54637b01fb236d4eba35dd81bea44b48ab41fbcdd79256b8a9c3cbfa9df198bbc7aefde66474567cb6bfd9d1719fed954fd4a61eb719f8d5e6330aafd57cbf6431d5fc05d05f7789cf897aa3a735376fd04b7789bf217dc74`
    }
  };
  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const banner = await axios.get(`${baseURL}/api/banners?populate=*`, config);
        console.log('bdata', banner);
        const bannarData = {
          ...banner.data?.data?.[0]?.attributes,
        };
        setBanner(bannarData);
        updateLoadingState("bannerLoading");
      } catch (error) {
        console.log("error is=> ", error);
      }
    };
    fetchBanner();
  }, []);
  useEffect(() => {
    if (!loading) {
      const setModalWidth = () => {
        const modalContent = document.querySelector(".modal-content");
        const windowWidth = window.innerWidth;
        if (modalContent) {
          modalContent.style.width = `${windowWidth - 40}px`;
        }
      };
      setModalWidth(); // Initial setup

      window.addEventListener("resize", setModalWidth); // Update on window resize
      return () => {
        window.removeEventListener("resize", setModalWidth); // Clean up the event listener
      };
    }
  }, [loading]);

  useEffect(() => {
    if (
      !loadingStates.bannerLoading &&
      !loadingStates.educationLoading &&
      !loadingStates.portfolioLoading
    ) {
      setLoading(false);
    }
  }, [loadingStates]);
  // stateLoaded ex: portfolioLoading - this funcitn will set the loadingstate.portfolioLoading as false as its loaded
  const updateLoadingState = (stateLoaded) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [stateLoaded]: false,
    }));
  };

  // Educatoin  state

  useEffect(() => {
    fetchEducationDataData();
  }, []);
  const fetchEducationDataData = async () => {
    const educationData = await axios.get(
      `${baseURL}/api/educations?populate=*`, config
    );
    setEducation(educationData.data.data);
    updateLoadingState("educationLoading"); 
  };

  // PortfolioState
  useEffect(() => {
    fetchPortfolioData();
  }, []);
  const fetchPortfolioData = async () => {
    try {
      const projectData = await axios.get(`${baseURL}/api/projects?populate=*`, config);
      setProjects(projectData.data.data);
      console.log("keywordData", projectData);
      const keywordData = await axios.get(`${baseURL}/api/keywords?populate=*`, config);
      setKeywords(keywordData.data.data);
      updateLoadingState("portfolioLoading");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div id="main-wrapper" className="main-wrapper">
      {loading ? (
        <div className="pl_container  text-center ">
          <div className="pl_wrap" style={{display:"inline-block"}}>
            <div className="pl">
              <div className="pl__dot"></div>
              <div className="pl__dot"></div>
              <div className="pl__dot"></div>
              <div className="pl__dot"></div>
              <div className="pl__dot"></div>
              <div className="pl__dot"></div>
              <div className="pl__dot"></div>
              <div className="pl__dot"></div>
              <div className="pl__dot"></div>
              <div className="pl__dot"></div>
              <div className="pl__dot"></div>
              <div className="pl__dot"></div>
              <div className="pl__text">Loading…</div>
            </div>
          </div>
        </div>
      ) : (
        <main className="main-page-wrapper ">
          <div
            data-elementor-type="wp-page"
            data-elementor-id={3880}
            className="elementor elementor-3880"
          >
            <Banner banner={banner} />
            <Pofrfolio projects={projects} keywords={keywords} />
            <Education education={education} />
          </div>
        </main>
      )}
    </div>
  );
};

export default App;
