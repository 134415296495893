import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import Checkbox from "@mui/material/Checkbox";
import { CopyBlock, a11yDark } from "react-code-blocks";
import HtmlRenderer from "./HtmlRender";
import { baseURL } from "../constant";

const Pofrfolio = ({ projects, keywords }) => {
  const [result, setResult] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [currentProject, setCurrentProject] = useState({});

  useEffect(() => {
    setResult(projects);
  }, []);

  function toggleKeyword(keyword) {
    const lowerKeyword = keyword.toLowerCase(); // convert keyword to lowercase
    setSelectedKeywords((prevKeywords) => {
      const index = prevKeywords.findIndex(
        (kw) => kw.toLowerCase() === lowerKeyword
      ); // check for lowercase match
      if (index === -1) {
        return [...prevKeywords, keyword];
      } else {
        return [
          ...prevKeywords.slice(0, index),
          ...prevKeywords.slice(index + 1),
        ];
      }
    });
  }
  function findMatchingPosts() {
    if (selectedKeywords.length < 1) {
      return setResult(projects);
    }
    const keywords = selectedKeywords;
    const matchingPosts = [];
    for (const post of projects) {
      for (const keyword of keywords) {
        const formattedKeyword = keyword?.toLowerCase()?.replace(/\s/g, "");
        const formattedPostKeywords = post.attributes.keywords.map((kw) =>
          kw?.toLowerCase()?.replace(/\s/g, "")
        );
        if (formattedPostKeywords.includes(formattedKeyword)) {
          matchingPosts.push(post);
          break;
        }
      }
    }
    setResult(matchingPosts);
  }

  return (
    <>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-3893ebb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="3893ebb"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4a08e25"
            data-id="4a08e25"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-e53ee3d elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                data-id="e53ee3d"
                data-element_type="widget"
                data-widget_type="divider.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-divider">
                    <span className="elementor-divider-separator"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-ea3e41a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="ea3e41a"
        data-element_type="section"
        id="portfolio"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d6d9ad6"
            data-id="d6d9ad6"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-9882410 rainbow-title-1 elementor-widget elementor-widget-rainbow-title"
                data-id={9882410}
                data-element_type="widget"
                data-widget_type="rainbow-title.default"
              >
                <div className="elementor-widget-container">
                  <div
                    className="section-title text-center "
                    data-aos="fade-up"
                    data-aos-duration={500}
                    data-aos-delay={100}
                    data-aos-once="true"
                  >
                    <h2 className="title sec-title">My Portfolio</h2>
                  </div>
                </div>
                <div>
                  <div className="filter mt-5">
                    {keywords.map((el, id) => (
                      <div id={id} className="filter_item">
                        <Checkbox
                          onClick={(e) => toggleKeyword(el.attributes?.title)}
                          color="secondary"
                          style={{
                            transform: "scale(1.3)",
                          }}
                        />
                        {el.attributes?.title}
                      </div>
                    ))}
                  </div>
                  <a
                    className="rn-btn rbbtn rn-group btn-medium  shadow-has btn-block text-center search_btn"
                    onClick={(e) => findMatchingPosts()}
                  >
                    Search
                  </a>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-33d70b6 elementor-widget elementor-widget-rainbow-post-grid"
                data-id="33d70b6"
                data-element_type="widget"
                data-widget_type="rainbow-post-grid.default"
              >
                <div className="elementor-widget-container">
                  <div className="rn-blog-area rt-departments">
                    <div className="row row--25">
                      {result.map((el) => (
                        <div
                          data-aos="fade-up"
                          data-aos-duration={500}
                          data-aos-delay={400}
                          data-aos-once="true"
                          className="rtin-item cp aos-init aos-animate col-lg-4 col-md-6 col-sm-6 col-12"
                        >
                          <div className="rn-blog post-card-only-popup">
                            <div className="inner h-100">
                              <div className="thumbnail">
                                <a
                                  className="thumb-only-popup"
                                  data-toggle="modal"
                                  onClick={(e) => {
                                    setCurrentProject(el);
                                  }}
                                  data-target="#exampleModalCenters-167960582551"
                                >
                                  <img
                                    width={340}
                                    height={250}
                                    src={`${baseURL}${el.attributes?.image?.data.attributes.url}`}
                                    style={{ height: "250px" }}
                                    className="attachment-rainbow-thumbnail-sm size-rainbow-thumbnail-sm wp-post-image"
                                    loading="lazy"
                                  />
                                </a>
                              </div>
                              <div className="content">
                                <div className="category-info">
                                  <div className="category-list">
                                    {el.attributes?.keywords?.map((el, id) => (
                                      <span className="keyword"> {el} </span>
                                    ))}
                                  </div>
                                </div>
                                <h4 className="title">
                                  <a
                                    className="title-only-popup"
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#exampleModalCenters-167960582551"
                                  >
                                    {el.attributes?.title}
                                  </a>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {/* Modal Blog Body area Start */}
                      <div
                        className="modal fade"
                        id="exampleModalCenters-167960582551"
                        tabIndex={-1}
                        role="dialog"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered modal-news"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">
                                  <GrClose />
                                </span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="post-thumbnail img-fluid modal-feat-im mb--30">
                                <img
                                  width={800}
                                  height={450}
                                  src={`${baseURL}${currentProject.attributes?.image?.data.attributes.url}`}
                                  className="img-fluid modal-feat-im in-radius wp-post-image"
                                  decoding="async"
                                  loading="lazy"
                                  sizes="(max-width: 800px) 100vw, 800px"
                                />
                              </div>
                              <div className="news-details">
                                <h4 className="title mb-2">
                                  {currentProject.attributes?.title}
                                </h4>
                                {/* <p className="mb-4">{currentProject.attributes?.Description}</p> */}
                                {
                                  <HtmlRenderer
                                    str={currentProject.attributes?.Description}
                                  />
                                }
                                <div>
                                  {currentProject?.attributes?.code_snippets?.data?.map(
                                    (el) => (
                                      <div>
                                        <h5 className="mb-4 mt-5">
                                          {el.attributes?.title}
                                        </h5>
                                        <CopyBlock
                                          text={el.attributes?.code}
                                          language="jsx"
                                          showLineNumbers={true}
                                          startingLineNumber={1}
                                          wrapLines={true}
                                          theme={a11yDark}
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* End of .modal-body */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pofrfolio;
