import React, { useEffect, useState } from "react"; 
import { baseURL } from "../constant";

const Education = ({ education}) => {
  return (
    <>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-1a397cb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="1a397cb"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-55bef81"
            data-id="55bef81"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-d74e8a8 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                data-id="d74e8a8"
                data-element_type="widget"
                data-widget_type="divider.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-divider">
                    <span className="elementor-divider-separator"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-85a85f9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="85a85f9"
        data-element_type="section"
        id="education"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9597907"
            data-id={9597907}
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-f936466 rainbow-title-1 elementor-widget elementor-widget-rainbow-title"
                data-id="f936466"
                data-element_type="widget"
                data-widget_type="rainbow-title.default"
              >
                <div className="elementor-widget-container">
                  <div
                    className="section-title text-center "
                    data-aos="fade-up"
                    data-aos-duration={500}
                    data-aos-delay={100}
                    data-aos-once="true"
                  >
                    <span className="subtitle sub-title">
                      Phd, Master and Bachelo
                    </span>
                    <h2 className="title sec-title">Education</h2>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-1d21dc5 elementor-widget elementor-widget-rainbow-experience-box"
                data-id="1d21dc5"
                data-element_type="widget"
                data-widget_type="rainbow-experience-box.default"
              >
                <div className="elementor-widget-container">
                  { education.map((el,i)=>(
                  <div
                  key={i}
                    data-aos="fade-up"
                    data-aos-duration={500}
                    data-aos-delay={100}
                    data-aos-once="true"
                    className="experience-style-two"
                  >
                    <div className="experience-left">
                      <div className="experience-image">
                        <img
                          width={600}
                          height={400}
                          src={`${baseURL}${el.attributes?.image?.data.attributes.url}`}
                          className="attachment-full size-full"
                          alt="university-image"
                          decoding="async"
                          loading="lazy"
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </div>
                      <div className="experience-center">
                        <span className="date">{el.attributes?.year}</span>
                        <h4 className="experience-title">{el.attributes?.year}</h4>
                        <h6 className="subtitle">{el.attributes?.university} </h6>
                        <p className="disc"> {el.attributes?.universityLocation} </p>
                      </div>
                    </div>
                  </div> ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Education;
