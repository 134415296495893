import React from "react";

import { SiGmail } from "react-icons/si";
import { MdLocationPin } from "react-icons/md";
import { FaUserAstronaut } from "react-icons/fa";
import { AiFillPhone, AiFillLinkedin } from "react-icons/ai";
import { baseURL } from "../constant";

const Banner = ({ banner }) => {
  return (
    <> 
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-fb14c8c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="fb14c8c"
        data-element_type="section"
        id="iam"
      >
        <div className="elementor-container elementor-column-gap-extended">
          <div
            className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-6a40a6f"
            data-id="6a40a6f"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-6fec0b8 elementor-widget elementor-widget-rainbow-about-me"
                data-id="6fec0b8"
                data-element_type="widget"
                data-widget_type="rainbow-about-me.default"
              >
                <div className="elementor-widget-container">
                  <div className="slide slider-style-3 pb-0">
                    <div className="slider-wrapper pt-0">
                      <div className="slider-info">
                        <div className="user-info-top">
                          <div className="user-info-header mb-1">
                            <div className="user">
                              <FaUserAstronaut />
                            </div>
                            <div
                              style={{
                                fontSize: "40px",
                              }}
                              dangerouslySetInnerHTML={{ __html: banner.title }}
                            />
                            <p className="disc"> {banner.about} </p>
                          </div>
                          <div className="user-info-footer">
                            <div className="info mb-0 mb-0" >
                              <SiGmail className="banner_svg" />
                              <span> {banner.email} </span>
                            </div>
                            <div className="info mb-0">
                              <AiFillPhone className="banner_svg" />
                              <span>{banner.phone} </span>
                            </div>
                            <div className="info mb-0">
                              <MdLocationPin className="banner_svg" />
                              <span>{banner.location}</span>
                            </div>
                            <div className="info mb-0">
                              <AiFillLinkedin className="banner_svg" />
                              <span> <a href={banner.linkedin} >Linkedin</a>  </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section
                className="mt-0 elementor-section elementor-inner-section elementor-element elementor-element-8a29a5f box-shadow elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="8a29a5f"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-0cefe7f"
                    data-id="0cefe7f"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-00e86fe elementor-widget elementor-widget-heading"
                        data-id="00e86fe"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <span className="elementor-heading-title elementor-size-default">
                            About Me
                          </span>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-7e1aeb7 elementor-widget elementor-widget-rb-button"
                        data-id="7e1aeb7"
                        data-element_type="widget"
                        data-widget_type="rb-button.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="button-group text-left">
                            <a
                              className="rn-btn rbbtn rn-group btn-medium  shadow-has"
                              href="#portfolio"
                            >
                              Portfolio
                            </a>
                            <a
                              className="rn-btn rbbtn rn-group btn-medium  shadow-has"
                              href="#education"
                            >
                              Education
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div
            className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-e2cc555"
            data-id="e2cc555"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-187488f elementor-widget elementor-widget-rainbow-image-holder"
                data-id="187488f"
                data-element_type="widget"
                data-widget_type="rainbow-image-holder.default"
              >
                <div className="elementor-widget-container">
                  <div className="rn-about-area">
                    <div
                      data-aos="fade-up"
                      data-aos-duration={1000}
                      data-aos-delay={100}
                      data-aos-once="true"
                      className="image-area"
                    >
                      <div className="thumbnail">
                        <img
                          width={800}
                          height={600}
                          src={`${baseURL}${banner.image?.data?.attributes?.url}`}
                          className="attachment-full size-full"
                          alt="banner-02"
                          decoding="async"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
